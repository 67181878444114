import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BasicJsonCodeEditor from "../components/CodeEditor/BasicJsonCodeEditor";
import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import Pagination from "../components/Pagination";
import ReloadButton from "../components/ReloadButton";
import { DataTable } from "../components/table/DataTable";
import { SidePanelContext } from "../context/SidePanelContext";
import { useEnvironment } from "../features/environments/api/getEnvironment";
import useIndexItems from "../features/indexes/api/useIndexItems";
import IndexActiveIcon from "../features/indexes/components/IndexActiveIcon";
import IndexDeployingIcon from "../features/indexes/components/IndexDeployingIcon";
import IndexItemsPanel from "../features/indexes/components/IndexItemsPanel";
import { IIndexItem } from "../features/indexes/types";
import { useSchema } from "../features/schemas/api/getSchema";
import { useSourceGroups } from "../features/source-groups/api/getSourceGroups";
import { ISourceInSourceGroup } from "../features/source-groups/types";
import formatDateTime from "../helpers/formatDateTime";

const IndexPage = () => {
  const title = "Index";

  const { environmentGuid, alias, version } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen: isQueryEditorOpen, onToggle: onToggleQueryEditor } =
    useDisclosure();
  const [selectedRow, setSelectedRow] = useState<string>("");

  const initialQuery = {
    filters: {},
    sort: {
      fields: [
        {
          field: "_updatedAt",
          order: "desc"
        }
      ]
    },
    pagination: {
      page: 0,
      pageSize: 20
    }
  };

  const [queryValue, setQueryValue] = useState(
    JSON.stringify(initialQuery, null, "\t")
  );

  const [query, setQuery] = useState(queryValue);
  const [sources, setSources] = useState<ISourceInSourceGroup[]>([]);
  const { data: sourceGroups, isLoading: isLoadingSourceGroups } =
    useSourceGroups();
  const { data: environment, isLoading: isLoadingEnvironment } =
    useEnvironment(environmentGuid);

  const { data: schema, isLoading: isSchemaLoading } = useSchema({
    environmentGuid,
    alias
  });

  const currentDeployment = version
    ? schema?.deployments.find((d) => d.version === parseInt(version))
    : undefined;

  const {
    setOpen: setOpenSidePanel,
    setTitle: setSidePanelTitle,
    setContent: setSidePanelContent,
    isClosing: isClosingSidePanel
  } = useContext(SidePanelContext);

  useEffect(() => {
    if (sourceGroups) {
      const sources = sourceGroups
        .map(function (p) {
          return p.sources;
        })
        .flat();

      setSources(sources);
    }
  }, [sourceGroups]);

  const {
    data: queryResult,
    isLoading,
    refetch,
    isRefetching
  } = useIndexItems({
    environmentGuid: environmentGuid,
    schemaAlias: `${alias ?? ""}-${version ?? ""}`,
    query: query,
    disabled: !currentDeployment
  });

  const handleOpenSidePanel = (id: string) => {
    const newSearch = new URLSearchParams(searchParams);
    newSearch.set("id", id);
    newSearch.set("sidePanel", "itemsPanel");
    setSearchParams(newSearch);
    setSelectedRow(id);
  };

  useEffect(() => {
    const isSidePanelOpen = searchParams.get("sidePanel") === "itemsPanel";
    if (isSidePanelOpen) {
      setSidePanelTitle("Item details");
      setSidePanelContent(<IndexItemsPanel />);
      setOpenSidePanel(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isClosingSidePanel) {
      setSelectedRow("");

      const newSearch = new URLSearchParams(searchParams);
      newSearch.delete("id");
      newSearch.delete("sidePanel");
      if (newSearch.toString() !== searchParams.toString()) {
        setSearchParams(searchParams);
      }
    }
  }, [isClosingSidePanel]);

  const columnHelper = createColumnHelper<IIndexItem>();

  const columns = [
    columnHelper.accessor("originId", {
      cell: (info) => info.getValue(),
      header: "Origin ID",
      enableSorting: false
    }),
    columnHelper.accessor("sourceName", {
      cell: (info) => info.getValue(),
      header: "Source Name",
      enableSorting: false
    }),
    columnHelper.accessor("updatedAt", {
      cell: (info) => formatDateTime(info.getValue()),
      header: "Updated at",
      enableSorting: false
    })
  ];

  return (
    <>
      <MetaTitle
        title={`${schema?.viewHandle ?? alias ?? ""} ${title}`}
      ></MetaTitle>
      <ContentBox title={`${alias ?? ""} ${title}`} childrenMt={4}>
        <Flex fontSize="sm" color="gray.400" gap={4} mb={8}>
          <Text>
            <Text as="strong">Environment: </Text>
            {environment?.name}
          </Text>
          <Text>
            <Text as="strong">Version: </Text>
            {version}
          </Text>
          <Flex>
            <Text pr="1" as="strong">
              Status:
            </Text>
            {(() => {
              if (isSchemaLoading) {
                return (
                  <Icon
                    className="fa-spin"
                    as={FontAwesomeIcon}
                    icon={faArrowRotateRight}
                  ></Icon>
                );
              } else if (currentDeployment?.publishedAt) {
                return <IndexActiveIcon />;
              } else {
                return <IndexDeployingIcon />;
              }
            })()}
          </Flex>
        </Flex>
        {!isQueryEditorOpen && (
          <Box>
            <SimpleGrid spacing={8} columns={2} mb="8">
              <Flex alignItems="flex-end">
                <Button onClick={onToggleQueryEditor} variant={"primary"}>
                  Edit as raw query
                </Button>
              </Flex>
              <Flex alignItems="flex-end">
                <ReloadButton loading={isRefetching} onClick={refetch} />
              </Flex>
            </SimpleGrid>
          </Box>
        )}
        {isQueryEditorOpen && (
          <Flex>
            <Button onClick={onToggleQueryEditor} variant={"subtle"}>
              Close raw query
            </Button>
          </Flex>
        )}
        <Collapse in={isQueryEditorOpen} animateOpacity>
          <Box mt="4">
            <BasicJsonCodeEditor
              value={queryValue}
              options={{}}
              onValueChange={(value) => setQueryValue(value ?? "")}
            />
            <Button onClick={onToggleQueryEditor} variant={"subtle"} mr="4">
              Close raw query
            </Button>
            <Button
              onClick={() => {
                setQuery(queryValue);
              }}
              variant={"primary"}
              mt="4"
              mb="4"
            >
              Run query
            </Button>
          </Box>
        </Collapse>
        <DataTable
          loading={
            isLoading ||
            isRefetching ||
            isLoadingSourceGroups ||
            isLoadingEnvironment
          }
          columns={columns}
          data={
            queryResult?.results.map((item) => ({
              id: item._id,
              originId: item._originId,
              sourceName:
                sources.find((x) => x.source.id.sourceGuid === item._sourceGuid)
                  ?.source.name ?? "",
              updatedAt: item._updatedAt
            })) ?? []
          }
          onRowClickCallback={(row: IIndexItem) => handleOpenSidePanel(row.id)}
          highlightedRow={selectedRow}
        />
        {queryResult && queryResult.totalResults > 0 && (
          <Pagination
            disabled={true}
            current={queryResult.results.length}
            total={queryResult.totalResults}
            loadMore={() => {
              return;
            }}
            loading={isLoading || isRefetching}
          />
        )}
      </ContentBox>
    </>
  );
};

export default IndexPage;
