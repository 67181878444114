import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { ENVIRONMENT } from "../../../constants/queryKey";
import { useTenantStore } from "../../tenants/store";
import { EnvironmentService } from "../environment.service";

export const getEnvironment = async (environmentId: string) => {
  const environmentService = container.resolve(EnvironmentService);
  return environmentService.getEnvironment(environmentId);
};

export const useEnvironment = (environmentId?: string) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({
      activeTenant
    }))
  );
  return useQuery({
    queryKey: [ENVIRONMENT, environmentId],
    queryFn: () => getEnvironment(environmentId ?? ""),
    enabled: !!activeTenant.id && !!environmentId
  });
};
