import { Box, Flex, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { IIndexTableItem } from "../types";
import IndexActiveIcon from "./IndexActiveIcon";
import IndexDeployingIcon from "./IndexDeployingIcon";
import IndexRemovingIcon from "./IndexRemovingIcon";

const Table = ({
  items,
  isLoading
}: {
  items: IIndexTableItem[];
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const NA_TEXT = "N/A";
  const columnHelper = createColumnHelper<IIndexTableItem>();
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      cell: ({ getValue, row }) => {
        const name = getValue();
        return (
          <Box key={row.original.id}>
            <Box>{name}</Box>
            <AdminTableRowId id={row.original.id} />{" "}
          </Box>
        );
      },
      header: "Name"
    }),
    columnHelper.accessor("deployments", {
      id: "version",
      header: "Version",
      cell: ({ getValue }) => {
        return getValue().map(({ version, status }, i) => (
          <Flex
            key={version ?? i}
            className={
              status === "deploying" || status === "removing" ? "fa-fade" : ""
            }
            color={
              status === "deploying" || status === "removing" ? "gray.500" : ""
            }
          >
            {version ?? "-"}
          </Flex>
        ));
      }
    }),
    columnHelper.accessor("deployments", {
      id: "status",
      header: "Status",
      cell: ({ getValue }) => {
        return getValue().map(({ status }) => {
          switch (status) {
            case "active":
              return <IndexActiveIcon />;
            case "deploying":
              return <IndexDeployingIcon />;
            case "notDeployed":
              return <Text>Not deployed</Text>;
            case "removing":
              return <IndexRemovingIcon />;
            default:
              return <Text>Unknown status</Text>;
          }
        });
      }
    }),
    columnHelper.accessor("deployments", {
      id: "deployedAt",
      header: "Deployed at",
      cell: ({ getValue }) => {
        return getValue().map(({ deployedAt, status, version }, i) => (
          <Text
            key={version ?? i}
            className={
              status === "deploying" || status === "removing" ? "fa-fade" : ""
            }
            color={
              status === "deploying" || status === "removing" ? "gray.500" : ""
            }
          >
            {deployedAt ?? NA_TEXT}
          </Text>
        ));
      }
    })
  ];
  return (
    <DataTable
      data={items}
      columns={columns}
      loading={isLoading}
      // TODO disable click event if index is not deployed
      onRowClickCallback={(item: IIndexTableItem) => {
        navigate(
          `/indexes/${item.environmentGuid}/${item.name}/${
            item.deployments[0].version ?? ""
          }`
        );
      }}
    ></DataTable>
  );
};

const IndexesTable = ({
  isLoading,
  isRefetching,
  items
}: {
  isLoading: boolean;
  isRefetching: boolean;
  items: IIndexTableItem[];
}) => {
  if (items.length === 0 && !isLoading && !isRefetching) {
    return <EmptyState type={EmptyStateType.NO_INDEXES} />;
  }
  return <Table items={items} isLoading={isLoading} />;
};

export default IndexesTable;
