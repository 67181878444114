import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ContentBox from "../components/ContentBox";
import EmptyState from "../components/EmptyState";
import MetaTitle from "../components/MetaTitle";
import { useEnvironments } from "../features/environments/api/getEnvironments";
import EnvironmentPicker from "../features/environments/components/EnvironmentPicker";
import { IEnvironmentResponse } from "../features/environments/types";
import useIndexes from "../features/indexes/api/useIndexes";
import IndexesTable from "../features/indexes/components/IndexesTable";
import { mapSchemasToIndexesTaleItems } from "../features/indexes/util/map";

const IndexesPage = () => {
  const title = "Indexes";
  const environments = useEnvironments();
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<IEnvironmentResponse | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const indexes = useIndexes({
    environmentId: selectedEnvironment?.id.idValue
  });

  const handleEnvironmentFilter = (value: IEnvironmentResponse | null) => {
    const newSearch = new URLSearchParams(searchParams);
    setSelectedEnvironment(value);

    if (value) {
      newSearch.set("env", value.id.environmentGuid);
      setSearchParams(newSearch);
    } else {
      setSearchParams(undefined);
    }
  };

  useEffect(() => {
    if (!environments) {
      return;
    }
    const searchEnvironment = searchParams?.get("env");
    const environmentOption = searchEnvironment
      ? environments.data?.find(
          (x) => x.id.environmentGuid === searchEnvironment
        ) ?? null
      : null;
    setSelectedEnvironment(environmentOption);
  }, [environments, searchParams]);

  return (
    <>
      <MetaTitle title={title}></MetaTitle>
      <ContentBox title={title}>
        <Flex w={"30rem"} mb={8}>
          <EnvironmentPicker
            selected={selectedEnvironment}
            environments={environments.data}
            onChange={(value) => {
              handleEnvironmentFilter(value);
            }}
            isClearable={true}
          />
        </Flex>
        {selectedEnvironment ? (
          <IndexesTable
            isLoading={indexes.isLoading}
            isRefetching={indexes.isRefetching}
            items={
              indexes.data
                ?.filter((x) => x.deployments.length !== 0)
                .map((x) =>
                  mapSchemasToIndexesTaleItems(
                    x,
                    selectedEnvironment?.id.environmentGuid
                  )
                ) ?? []
            }
          ></IndexesTable>
        ) : (
          <EmptyState customDescription="Please select an environment so we can find some indexes for you" />
        )}
      </ContentBox>
    </>
  );
};

export default IndexesPage;
