import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INDEX } from "../../../constants/queryKey";
import { IndexesService } from "../indexes.service";

const getIndexItem = ({
  environmentGuid,
  schemaAlias,
  id
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  id?: string;
}) => {
  const indexesService = container.resolve(IndexesService);

  if (!environmentGuid || !schemaAlias || !id) {
    return null;
  }

  return indexesService.getIndexItem({
    environmentGuid: environmentGuid,
    schemaAlias: schemaAlias,
    id: id
  });
};

const useIndexItem = ({
  environmentGuid,
  schemaAlias,
  id
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  id?: string;
}) => {
  return useQuery({
    queryKey: [INDEX, environmentGuid, schemaAlias, id],
    queryFn: () =>
      getIndexItem({
        environmentGuid: environmentGuid,
        schemaAlias: schemaAlias,
        id: id
      }),
    enabled: !!environmentGuid && !!schemaAlias && !!id
  });
};

export default useIndexItem;
