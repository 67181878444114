import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { useShallow } from "zustand/react/shallow";
import { SCHEMAS } from "../../../constants/queryKey";
import { useTenantStore } from "../../tenants/store";
import { SchemaService } from "../schema.service";

export const getSchemas = async (
  query?: string,
  type?: string,
  environmentGuid?: string
) => {
  const schemaService = container.resolve(SchemaService);

  return !query
    ? schemaService.getSchemas({ type, environmentGuid })
    : schemaService.searchSchemas(query);
};

interface SchemaQueryOptions {
  type?: string;
  query?: string;
  environmentGuid?: string;
  refetch?: boolean;
}

export const useSchemas = (options: SchemaQueryOptions = {}) => {
  const { type, query, environmentGuid, refetch } = options;
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({
      activeTenant
    }))
  );

  return useQuery({
    queryKey: [SCHEMAS, query, type, environmentGuid],
    queryFn: () => getSchemas(query, type, environmentGuid),
    refetchInterval: refetch ? 10000 : undefined,
    enabled: !!activeTenant.id
  });
};
