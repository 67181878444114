import formatDateTime from "../../../helpers/formatDateTime";
import { ISchemaListResponse } from "../../schemas/types";
import { IIndexTableDeployment, IIndexTableItem } from "../types";

export const mapSchemasToIndexesTaleItems = (
  d: ISchemaListResponse,
  environmentGuid: string
): IIndexTableItem => {
  const { deployments, id, viewHandle } = d;

  return {
    id: id.idValue,
    name: viewHandle,
    environmentGuid: environmentGuid,
    deployments:
      deployments.length === 0
        ? [{ status: "notDeployed" }]
        : [...deployments]
            .sort(
              (a, b) =>
                new Date(b.publishedAt ?? "").getTime() -
                new Date(a.publishedAt ?? "").getTime()
            )
            .reduce((acc, { releasedAt, publishedAt, version }) => {
              if (!publishedAt) {
                return [
                  ...acc,
                  {
                    status: "deploying",
                    deployedAt: formatDateTime(releasedAt),
                    version: version ?? undefined
                  } as IIndexTableDeployment
                ];
              }

              const existingActiveVersion = acc.find(
                (d) => d.version === version && d.status === "active"
              );
              if (existingActiveVersion) {
                return [
                  ...acc,
                  {
                    status: "removing",
                    deployedAt: formatDateTime(releasedAt),
                    version: version ?? undefined
                  } as IIndexTableDeployment
                ];
              }

              return [
                ...acc,
                {
                  status: "active",
                  deployedAt: releasedAt
                    ? formatDateTime(releasedAt)
                    : undefined,
                  version: version ?? undefined
                } as IIndexTableDeployment
              ];
            }, [] as IIndexTableDeployment[])
  };
};
