import { injectAll, singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";

import { INJECTION_TOKEN_FORMATTERS } from "./formats/SchemaVersionFormat.module";
import {
  DryRunInputFormat,
  ISchemaDryRunResultDto,
  ISchemaDryRunSourceEntityDto
} from "./testing-schemas/types";
import {
  ISchemaCreatePayload,
  ISchemaDuplicatePayload,
  ISchemaEditPayload,
  ISchemaListResponse,
  ISchemaResponse,
  ISchemaResponseId,
  ISchemaSearchListResponse,
  ISchemaVersionEditPayload,
  ISchemaVersionFormat
} from "./types";

interface GetSchemasOptions {
  type?: string;
  environmentGuid?: string;
}

@singleton()
export class SchemaService {
  private readonly mappingSchemasUrl = "/api/v1/tenant/mapping-schemas";
  private readonly mappingSchemasSearchUrl =
    "/api/v1/tenant/mapping-schemas/search";

  private readonly errorMessageNoFormatter =
    "No schema formatter for schema format";

  constructor(
    private readonly managementClient: ManagementClient,
    @injectAll(INJECTION_TOKEN_FORMATTERS)
    private readonly formats: ISchemaVersionFormat[]
  ) {}

  public async createSchema(payload: ISchemaCreatePayload) {
    const format = this.formats.find((f) => f.canHandle(payload.format));
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }
    const { data } = await this.managementClient.post<ISchemaResponseId>(
      this.mappingSchemasUrl,
      {
        ...payload,
        data: payload.data ? format.postProcessValue(payload.data) : null
      }
    );
    return data;
  }

  public async deleteSchema(schemaGuid: string) {
    const { data } = await this.managementClient.delete(
      `${this.mappingSchemasUrl}/${schemaGuid}`
    );
    return data;
  }

  public async duplicateSchema(payload: ISchemaDuplicatePayload) {
    const existingSchema = await this.managementClient.get<ISchemaResponse>(
      `${this.mappingSchemasUrl}/${payload.schemaGuid}`
    );

    const format = this.formats.find((f) =>
      f.canHandle(existingSchema.data.version.format)
    );
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }

    // Create new schema
    return this.managementClient.post<ISchemaResponseId>(
      this.mappingSchemasUrl,
      {
        name: payload.name,
        viewHandle: payload.viewHandle,
        format: existingSchema.data.version.format,
        type: existingSchema.data.type,
        data: existingSchema.data.version
          ? format.postProcessValue(
              format.preProcessValue(existingSchema.data.version.data)
            )
          : undefined
      }
    );
  }

  public async editSchema(schemaGuid: string, payload: ISchemaEditPayload) {
    const { data } = await this.managementClient.put(
      `${this.mappingSchemasUrl}/${schemaGuid}`,
      payload
    );
    return data;
  }

  public async editSchemaVersion(
    schemaGuid: string,
    version: number,
    payload: ISchemaVersionEditPayload
  ) {
    const format = this.formats.find((f) => f.canHandle(payload.format));
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }
    const { data } = await this.managementClient.put(
      `${this.mappingSchemasUrl}/${schemaGuid}/version/${version}`,
      { ...payload, schema: format.postProcessValue(payload.schema) }
    );
    return data;
  }

  public async getSchema(schemaGuid: string): Promise<ISchemaResponse> {
    const { data } = await this.managementClient.get<ISchemaResponse>(
      `${this.mappingSchemasUrl}/${schemaGuid}`
    );

    const format = this.formats.find((f) => f.canHandle(data.version.format));
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }
    return {
      ...data,
      version: {
        ...data.version,
        data: format.preProcessValue(data.version.data)
      }
    };
  }

  public async getSchemaByAlias(alias: string, environmentGuid?: string) {
    const { data } = await this.managementClient.get<ISchemaResponse>(
      `${this.mappingSchemasUrl}?alias=${alias}`,
      { params: { environmentGuid } }
    );
    const format = this.formats.find((f) => f.canHandle(data.version.format));
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }
    return {
      ...data,
      version: {
        ...data.version,
        data: format.preProcessValue(data.version.data)
      }
    };
  }

  public async getSchemaVersion(
    schemaGuid: string,
    version: number
  ): Promise<ISchemaResponse> {
    const { data } = await this.managementClient.get<ISchemaResponse>(
      `${this.mappingSchemasUrl}/${schemaGuid}`,
      {
        params: { version }
      }
    );
    const format = this.formats.find((f) => f.canHandle(data.version.format));
    if (!format) {
      throw new Error(this.errorMessageNoFormatter);
    }
    return {
      ...data,
      version: {
        ...data.version,
        data: format.preProcessValue(data.version.data)
      }
    };
  }

  public async getSchemas({
    type,
    environmentGuid
  }: GetSchemasOptions | undefined = {}) {
    const { data } = await this.managementClient.get<ISchemaListResponse[]>(
      this.mappingSchemasUrl,
      { params: { schemaType: type, environmentGuid } }
    );
    return data;
  }

  public async searchSchemas(filter: string) {
    const { data } = await this.managementClient.post<
      ISchemaSearchListResponse[]
    >(this.mappingSchemasSearchUrl, {
      query: filter
    });
    return data;
  }

  public async test(
    dto: ISchemaDryRunSourceEntityDto,
    format: DryRunInputFormat
  ) {
    const { data } = await this.managementClient.post<ISchemaDryRunResultDto>(
      `/api/v1/tenant/mapping-schemas/arbitrary-input-dry-run`,
      dto,
      {
        params: {
          format
        }
      }
    );
    return data;
  }
}
