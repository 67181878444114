import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import BasicJsonCodeEditor from "../../../components/CodeEditor/BasicJsonCodeEditor";
import ReloadButton from "../../../components/ReloadButton";
import formatDateTime from "../../../helpers/formatDateTime";
import useIndexItem from "../api/useIndexItem";

const IndexItemsPanel = () => {
  const navigate = useNavigate();
  const {
    environmentGuid,
    alias: schemaAlias,
    version: schemaVersion
  } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data, isLoading, refetch, isRefetching } = useIndexItem({
    environmentGuid: environmentGuid,
    schemaAlias: `${schemaAlias ?? ""}-${schemaVersion ?? ""}`,
    id: id ?? undefined
  });

  const stringFormattedItem = () => {
    if (data) {
      return JSON.stringify(data, null, "\t");
    } else {
      return "";
    }
  };

  if (!id) {
    return <></>;
  }

  return (
    <Box>
      <Flex justifyContent="space-between">
        <IconButton
          variant="ghost"
          colorScheme="brand"
          aria-label="Back"
          onClick={() => {
            navigate(-1);
          }}
          icon={<Icon as={FontAwesomeIcon} icon={faArrowLeft} />}
        />

        <ReloadButton loading={isRefetching} onClick={refetch} />
      </Flex>
      {isLoading || isRefetching ? (
        <Stack>
          <Skeleton height="40px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="40px" />
          <Skeleton mt="4" height="300px" />
        </Stack>
      ) : (
        <Box fontSize="sm" mt="2" position="relative">
          <VStack alignItems="flex-start">
            <Text>
              <Text as="b">Item ID: </Text>
              {id}
            </Text>
            <Text>
              <Text as="b">Source entity origin ID: </Text>
              <Link
                as={RouterLink}
                color="brand.900"
                to={`/source-entities/${data?._sourceGuid ?? ""}/${
                  data?._originId ?? ""
                }`}
              >
                <Button variant="link" ml="1">
                  {data?._originId}
                </Button>
              </Link>
            </Text>
            <Text>
              <Text as="b">Schema alias: </Text>
              <Link
                as={RouterLink}
                color="brand.900"
                to={`/schemas/${schemaAlias ?? ""}`}
              >
                <Button variant="link" ml="1">
                  {schemaAlias}
                </Button>
              </Link>
            </Text>
            <Text>
              <Text as="b">Updated at: </Text>
              {formatDateTime(data?._updatedAt)}
            </Text>
          </VStack>
          <Box mt="8">
            <BasicJsonCodeEditor
              value={stringFormattedItem()}
              options={{
                readOnly: true
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default IndexItemsPanel;
