import { singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { IQueryResult, ISingleQueryItem } from "./types";

@singleton()
export class IndexesService {
  constructor(private readonly managementClient: ManagementClient) {}

  public async getIndexItems({
    environmentGuid,
    schemaAlias,
    query
  }: {
    environmentGuid: string;
    schemaAlias: string;
    query: string;
  }) {
    const { data } = await this.managementClient.post<IQueryResult>(
      `/api/v1/tenant/index/${environmentGuid}/${schemaAlias.toLowerCase()}`,
      JSON.parse(query)
    );
    return data;
  }

  public async getIndexItem({
    environmentGuid,
    schemaAlias,
    id
  }: {
    environmentGuid: string;
    schemaAlias: string;
    id: string;
  }) {
    const { data } = await this.managementClient.get<ISingleQueryItem>(
      `/api/v1/tenant/index/${environmentGuid}/${schemaAlias.toLowerCase()}/${id}`
    );
    return data;
  }
}
