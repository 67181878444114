import {
  Box,
  Button,
  Flex,
  FocusLock,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { createConditionId } from "../helpers/initQueryConditionFromSearchParams";
import {
  IFilterableFieldOption,
  IFrontendCondition,
  ILogsState,
  LogsAction
} from "../types";
import AddOrEditFilterCondition from "./AddOrEditFilterCondition";
import LogConditionButton from "./LogConditionButton";
import LogsDatePeriodFilter from "./LogsDatePeriodFilter";

const LogsFilteringBar = ({
  findByValue,
  logsState,
  logsDispatch,
  filterFields,
  left,
  rightStart,
  rightEnd
}: {
  findByValue: (value: string) => IFilterableFieldOption | null | undefined;
  logsState: ILogsState;
  logsDispatch: React.Dispatch<LogsAction>;
  filterFields: IFilterableFieldOption[];
  left?: JSX.Element;
  rightEnd?: JSX.Element;
  rightStart?: JSX.Element;
}) => {
  const [editCondition, setEditCondition] = useState<
    IFrontendCondition | undefined
  >(undefined);
  const [createCondition, setCreateCondition] = useState<
    IFrontendCondition | undefined
  >(undefined);

  const items: IFrontendCondition[] = logsState.condition?.items ?? [];
  const add = function () {
    const condition: IFrontendCondition = {
      id: createConditionId(),
      $type: "EQUAL"
    };
    setCreateCondition(condition);
    setEditCondition(undefined);
  };

  const onEditCondition = function (condition: IFrontendCondition) {
    setEditCondition(condition);
    setCreateCondition(undefined);
  };

  const onRemoveCondition = function (condition: IFrontendCondition) {
    const updatedConditions = [...items];
    const matchingIndex = updatedConditions.findIndex(
      (f) => f.id === condition.id
    );
    if (matchingIndex >= 0) {
      updatedConditions.splice(matchingIndex, 1);
      logsDispatch({
        type: "set_condition",
        value: updatedConditions
      });
    }
  };

  const onCloseEditPopover = function () {
    setEditCondition(undefined);
  };

  const onCloseCreatePopover = function () {
    setCreateCondition(undefined);
  };

  const onSuccessCreatePopover = function (condition: IFrontendCondition) {
    const updatedConditions = [...items, condition];
    logsDispatch({
      type: "set_condition",
      value: updatedConditions
    });
    setCreateCondition(undefined);
  };

  const onSuccessEditPopover = function (condition: IFrontendCondition) {
    const updatedConditions = [...items];
    const matchingIndex = updatedConditions.findIndex(
      (f) => f.id === condition.id
    );
    if (matchingIndex >= 0) {
      updatedConditions[matchingIndex] = condition;
    } else {
      updatedConditions.push(condition);
    }

    logsDispatch({
      type: "set_condition",
      value: updatedConditions
    });
    setEditCondition(undefined);
  };

  return (
    <>
      <Flex mb={3}>
        <Box>
          <Popover
            isOpen={createCondition !== undefined}
            onClose={onCloseCreatePopover}
            placement="bottom-start"
            closeOnBlur={false}
            isLazy={true}
          >
            <PopoverTrigger>
              <Button
                leftIcon={<Icon as={FontAwesomeIcon} icon={faAdd}></Icon>}
                onClick={add}
              >
                Add filter
              </Button>
            </PopoverTrigger>
            <PopoverContent p={4}>
              <FocusLock restoreFocus persistentFocus={false}>
                <PopoverArrow />
              </FocusLock>
              <AddOrEditFilterCondition
                filterFields={filterFields}
                condition={createCondition}
                onClose={onCloseCreatePopover}
                onSuccess={onSuccessCreatePopover}
              />
            </PopoverContent>
          </Popover>
          {left}
        </Box>
        <Spacer />
        <Flex direction={"row"} gap={2} alignItems={"center"}>
          {rightStart}
          <LogsDatePeriodFilter
            logsState={logsState}
            logsDispatch={logsDispatch}
          ></LogsDatePeriodFilter>
          {rightEnd}
        </Flex>
      </Flex>

      <Wrap spacing="10px" mt={2} mb={4}>
        {items.map((item) => (
          <WrapItem key={item.id}>
            <Popover
              isLazy={true}
              isOpen={editCondition === item}
              onClose={onCloseEditPopover}
              placement="bottom-start"
              closeOnBlur={false}
              matchWidth={true}
            >
              <PopoverTrigger>
                <LogConditionButton
                  findByValue={findByValue}
                  key={item.id}
                  condition={item}
                  onEdit={() => onEditCondition(item)}
                  onDelete={() => onRemoveCondition(item)}
                />
              </PopoverTrigger>
              <PopoverContent p={4}>
                <FocusLock restoreFocus persistentFocus={false}>
                  <PopoverArrow />
                </FocusLock>
                <AddOrEditFilterCondition
                  filterFields={filterFields}
                  condition={editCondition}
                  onClose={onCloseEditPopover}
                  onSuccess={onSuccessEditPopover}
                />
              </PopoverContent>
            </Popover>
          </WrapItem>
        ))}
      </Wrap>
    </>
  );
};
export default LogsFilteringBar;
