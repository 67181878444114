import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { container } from "tsyringe";
import { DEPLOYMENTS_FROM_ENVIRONMENTS } from "../../../constants/queryKey";
import { DeploymentService } from "../deployment.service";
import {
  IBulkDeployResponse,
  IBulkDeployResponseCurrentSchema
} from "../types";
import { BulkDeploy } from "../types/BulkDeploy";
import { deployStateSortOrder } from "../util";

export const getDeploymentsFromEnvironment = async ({
  environmentGuid
}: {
  environmentGuid?: string;
}) => {
  if (!environmentGuid) {
    return [];
  }
  const deploymentService = container.resolve(DeploymentService);
  const response = await deploymentService.getDeploymentByEnvironment(
    environmentGuid
  );
  return mapBulkDeployResponseToSchemaList(response);
};

const mapBulkDeployResponseToSchemaList = (data: IBulkDeployResponse) => {
  const filterDuplicates = (
    schema: IBulkDeployResponseCurrentSchema
  ): boolean =>
    !data.pending.schemas.some((s) => schema.id.idValue === s.id.idValue);

  return orderBy(
    [
      ...data.pending.schemas.map(
        (schema) =>
          new BulkDeploy({
            ...schema,
            schemaType: "pending",
            id: schema.id.idValue,
            mappingSchemaGuid: schema.id.mappingSchemaGuid,
            version: {
              label: schema.version.toString(),
              value: schema.version.toString()
            },
            deployVersion: {
              label: `${schema.version} (latest)`,
              value: schema.version.toString()
            },
            currentVersion:
              data.current.schemas.find(
                (current) => current.id.idValue === schema.id.idValue
              )?.version ?? null,
            exclude: false,
            useVersionFromDifferentEnv: false,
            useVersionFromDifferentEnvClass: false
          })
      ),
      ...data.current.schemas.filter(filterDuplicates).map(
        (schema) =>
          new BulkDeploy({
            ...schema,
            schemaType: "current",
            id: schema.id.idValue,
            mappingSchemaGuid: schema.id.mappingSchemaGuid,
            version: {
              label: schema.version.toString(),
              value: schema.version.toString()
            },
            deployVersion: {
              label: `${schema.version} (latest)`,
              value: schema.version.toString()
            },
            currentVersion: schema.version,
            useVersionFromDifferentEnv: false,
            useVersionFromDifferentEnvClass: false,
            exclude: false
          })
      )
    ],
    [({ state }) => deployStateSortOrder[state], "alias"],
    ["asc", "desc"]
  );
};

export const useDeploymentsFromEnvironment = ({
  environmentGuid
}: {
  environmentGuid?: string;
}) => {
  return useQuery({
    queryKey: [DEPLOYMENTS_FROM_ENVIRONMENTS, environmentGuid],
    queryFn: () => getDeploymentsFromEnvironment({ environmentGuid }),
    enabled: !!environmentGuid
  });
};
