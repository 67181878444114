import {
  AdvancedSearchAction,
  CollapseAction,
  CollapseAllAction,
  EnableAdvancedSearchAction,
  EnableSimpleSearchAction,
  ExpandAction,
  ExpandAllAction,
  InitializeAction,
  ITreeState,
  Paths,
  SchemaItemTreeNode,
  SchemasTreeAction,
  SearchAction,
  TreeNode,
  VirtualFolderTreeNode
} from "../types/schema-list";
import { buildNodes } from "../utils/iterativeBuildFolderTree";

// Reducer
export const Reducer = (state: ITreeState, action: SchemasTreeAction) => {
  switch (action.type) {
    case "initialize":
      return initializeActionHandler(state, action);
    case "collapse_all":
      return collapseAllActionHandler(state, action);
    case "expand_all":
      return expandAllActionHandler(state, action);
    case "advanced_search":
      return enableAdvancedActionHandler(state, action);
    case "simple_search":
      return enableSimpleActionHandler(state, action);
    case "search":
      return searchActionHandler(state, action);
    case "advancedSearch":
      return advancedSearchActionHandler(state, action);
    case "collapse":
      return collapseActionHandler(state, action);
    case "expand":
      return expandActionHandler(state, action);
    default:
      return state;
  }
};

// Actions - enable simple search
const collapseAllActionHandler = (
  state: ITreeState,
  _action: CollapseAllAction
): ITreeState => {
  return {
    ...state,
    nodes: setNodesExpansion(state.nodes, false),
    alwaysOpen: false,
    // Clear all expanded and collected paths on expansion of all nodes
    collapsedPaths: new Paths(),
    expandedPaths: new Paths()
  } as ITreeState;
};

// Actions - enable advanced search
const enableAdvancedActionHandler = (
  state: ITreeState,
  _action: EnableAdvancedSearchAction
): ITreeState => {
  // const filterTermUpdatedState = updateSearchState(state, "");
  return {
    ...state,
    advancedSearch: true,
    alwaysOpen: false,
    filterTerm: "",
    nodes: setNodesExpansion(state.nodes, false)
  } as ITreeState;
};

// Actions - collapse all
const enableSimpleActionHandler = (
  state: ITreeState,
  _action: EnableSimpleSearchAction
): ITreeState => {
  return {
    ...state,
    advancedSearch: false,
    query: "",
    nodes: setNodesExpansion(state.nodes, false),
    alwaysOpen: false,
    // Clear all expanded and collected paths on expansion of all nodes
    collapsedPaths: new Paths(),
    expandedPaths: new Paths()
  } as ITreeState;
};

// Actions - collapse all
const expandAllActionHandler = (
  state: ITreeState,
  _action: ExpandAllAction
): ITreeState => {
  return {
    ...state,
    nodes: setNodesExpansion(state.nodes, true),
    alwaysOpen: true,
    // Clear all expanded and collected paths on expansion of all nodes
    collapsedPaths: new Paths(),
    expandedPaths: new Paths()
  } as ITreeState;
};

// Actions - toggle everything to explicit expansion state
const setFolderNodeExpansion = (
  node: VirtualFolderTreeNode,
  expanded: boolean
): VirtualFolderTreeNode => {
  return {
    ...node,
    isExpanded: expanded,
    children: setNodesExpansion(node.children, expanded)
  };
};

const setNodesExpansion = (
  nodes: TreeNode[],
  expanded: boolean
): TreeNode[] => {
  return nodes.map((node) => {
    if (node.type === "folder") {
      return setFolderNodeExpansion(node as VirtualFolderTreeNode, expanded);
    }

    return { ...node, isExpanded: expanded };
  });
};

// Actions - Search
const searchActionHandler = (
  state: ITreeState,
  action: SearchAction
): ITreeState => {
  const filterTermUpdatedState = updateSearchState(state, action.term);
  return {
    ...filterTermUpdatedState,
    // When search term is empty, we collapse all nodes, else we expand all nodes
    nodes: setNodesExpansion(
      filterTermUpdatedState.nodes,
      filterTermUpdatedState.alwaysOpen
    )
  };
};

// Actions - Advanced search
const advancedSearchActionHandler = (
  state: ITreeState,
  action: AdvancedSearchAction
): ITreeState => {
  const filterTermUpdatedState = updateAdvancedSearchState(state, action.query);
  return {
    ...filterTermUpdatedState,
    // When search term is empty, we collapse all nodes, else we expand all nodes
    nodes: setNodesExpansion(
      filterTermUpdatedState.nodes,
      filterTermUpdatedState.alwaysOpen
    )
  };
};

const updateSearchState = (state: ITreeState, term: string): ITreeState => {
  const filteredNodes = searchNodeTreeRecursively(state.nodes, term);
  return {
    ...state,
    collapsedPaths: new Paths(),
    expandedPaths: new Paths(),
    filterTerm: term,
    alwaysOpen: term.length > 0,
    nodes: filteredNodes
  };
};

const updateAdvancedSearchState = (
  state: ITreeState,
  query: string
): ITreeState => {
  return {
    ...state,
    collapsedPaths: new Paths(),
    expandedPaths: new Paths(),
    query: query,
    alwaysOpen: query.length > 0
  };
};

const filterFolderNode = (node: VirtualFolderTreeNode, term: string) => {
  node.hidden =
    !node.path?.toLowerCase().includes(term.toLowerCase()) &&
    !node.name?.toLowerCase().includes(term.toLowerCase());
  node.children = searchNodeTreeRecursively(node.children, term);
  node.hidden = node.children.every((child) => child.hidden);
  return node;
};

const filterSchemaNode = (node: SchemaItemTreeNode, term: string) => {
  const lowerCaseTerm = term.toLowerCase();
  const lowerCaseSchemaGuid = node.data.id?.mappingSchemaGuid?.toLowerCase();
  node.hidden =
    !lowerCaseSchemaGuid.includes(lowerCaseTerm) &&
    !lowerCaseTerm?.includes(lowerCaseSchemaGuid) &&
    !node.data.name?.toLowerCase().includes(lowerCaseTerm) &&
    !node.data.viewHandle?.toLowerCase().includes(lowerCaseTerm);
  return node;
};

const searchNodeTreeRecursively = (
  nodes: TreeNode[],
  term: string
): TreeNode[] => {
  return nodes.map((node) => {
    switch (node.type) {
      case "folder":
        return filterFolderNode(node as VirtualFolderTreeNode, term);
      case "schema":
        return filterSchemaNode(node as SchemaItemTreeNode, term);
      default:
        return node;
    }
  });
};

// Actions - Expand single path
const expandPathHierarchy = (path: string): string[] => {
  const parts = path.split("/");
  const descendantPaths: string[] = [];
  for (let i = 0; i < parts.length; i++) {
    if (i === 0) {
      descendantPaths.push(parts[i]);
      continue;
    }

    descendantPaths.push(`${descendantPaths[i - 1]}/${parts[i]}`);
  }
  return descendantPaths;
};

const expandActionHandler = (
  state: ITreeState,
  action: ExpandAction
): ITreeState => {
  const paths = expandPathHierarchy(action.path);
  const collapsedPaths = new Paths(Array.from(state.collapsedPaths));
  collapsedPaths.deleteRange(paths);

  // If entire tree is collapsed, we expand only affected paths
  const expandedPaths = new Paths(Array.from(state.expandedPaths));
  if (!state.alwaysOpen) {
    expandedPaths.addRange(paths);
  }

  return {
    ...state,
    collapsedPaths,
    expandedPaths,
    nodes: expandNodesByPath(state.nodes, action.path)
  };
};

/**
 * Expands folders based on provided path.
 * E.g. if path is `Countries`, we only expand `Countries`
 * E.g. if path is `Countries/Europe`, we expand `Countries/Europe` and its ascendant `Countries`
 * E.g. if path is `Countries/Europe/Denmark`, we expand `Countries/Europe/Denmark` and its ascendants
 */
const expandNodesByPath = (nodes: TreeNode[], path: string): TreeNode[] => {
  return nodes.map((node) => {
    if (node.type !== "folder") {
      return node;
    }
    const folderNode = node as VirtualFolderTreeNode;

    // Ascendant
    if (path.startsWith(`${folderNode.path}/`)) {
      return {
        ...folderNode,
        isExpanded: true,
        children: expandNodesByPath(folderNode.children, path)
      };
    }

    // Target
    if (folderNode.path === path) {
      return {
        ...folderNode,
        isExpanded: true,
        children: expandNodesByPath(folderNode.children, path)
      };
    }

    return folderNode;
  });
};

// Actions - Collapse single path
const collapseActionHandler = (
  state: ITreeState,
  action: CollapseAction
): ITreeState => {
  const expandedPaths = new Paths(Array.from(state.expandedPaths));
  const selfOrDescendantPaths = expandedPaths.descendantsOrSelf(action.path);
  expandedPaths.deleteRange(selfOrDescendantPaths);

  // If entire tree is expanded, we collapse only affected paths
  const collapsedPaths = new Paths(Array.from(state.collapsedPaths));
  if (state.alwaysOpen) {
    collapsedPaths.addRange(selfOrDescendantPaths);
  }

  return {
    ...state,
    collapsedPaths,
    expandedPaths,
    nodes: collapseNodesByPath(state.nodes, action.path)
  };
};

/**
 * Collapses folders based on provided path.
 * E.g. if path is `Countries/Europe/Denmark`, we only collapse `Countries/Europe/Denmark`
 * E.g. if path is `Countries/Europe`, we collapse `Countries/Europe` and its descendants
 * E.g. if path is `Countries`, we collapse `Countries` and its descendants
 */
const collapseNodesByPath = (nodes: TreeNode[], path: string): TreeNode[] => {
  return nodes.map((node) => {
    if (node.type !== "folder") {
      return node;
    }
    const folderNode = node as VirtualFolderTreeNode;

    // Ascendant
    if (path.startsWith(`${folderNode.path}/`)) {
      return {
        ...folderNode,
        children: collapseNodesByPath(folderNode.children, path)
      };
    }

    // Target/descendant
    if (folderNode.path === path || folderNode.path?.startsWith(`${path}/`)) {
      return {
        ...folderNode,
        isExpanded: false,
        children: collapseNodesByPath(folderNode.children, path)
      };
    }

    return folderNode;
  });
};

// Action - Initialize
const initializeActionHandler = (
  state: ITreeState,
  {
    alwaysOpen,
    advancedSearch,
    collapsedPaths,
    expandedPaths,
    filterTerm,
    query,
    schemas
  }: InitializeAction
): ITreeState => {
  const nodes = buildNodes(schemas ?? []);
  const newState: ITreeState = {
    alwaysOpen,
    advancedSearch,
    nodes,
    filterTerm,
    query,
    collapsedPaths: new Paths(collapsedPaths),
    expandedPaths: new Paths(expandedPaths),
    hasFolders: nodes.some((node) => node.type === "folder")
  };

  return applyExplictlyCollapsedPaths(
    applyExplictlyExpandedPaths(applyFilter(applyAlwaysOpen(newState)))
  );
};

const applyExplictlyCollapsedPaths = (state: ITreeState): ITreeState => {
  state.collapsedPaths.forEach((path) => {
    state.nodes = collapseNodesByPath(state.nodes, path);
  });
  return state;
};

const applyExplictlyExpandedPaths = (state: ITreeState): ITreeState => {
  state.expandedPaths.forEach((path) => {
    state.nodes = expandNodesByPath(state.nodes, path);
  });
  return state;
};

const applyAlwaysOpen = (state: ITreeState): ITreeState => {
  if (!state.alwaysOpen) {
    return state;
  }

  state.nodes = setNodesExpansion(state.nodes, true);
  return state;
};

const applyFilter = (state: ITreeState): ITreeState => {
  if (state.filterTerm.length === 0) {
    return state;
  }

  return searchActionHandler(state, {
    term: state.filterTerm,
    type: `search`
  });
};
