import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INDEX } from "../../../constants/queryKey";
import { IndexesService } from "../../indexes/indexes.service";

const getIndexItems = ({
  environmentGuid,
  schemaAlias,
  query
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  query?: string;
}) => {
  const indexesService = container.resolve(IndexesService);

  if (!environmentGuid || !schemaAlias || !query) {
    return null;
  }

  return indexesService.getIndexItems({
    environmentGuid: environmentGuid,
    schemaAlias: schemaAlias,
    query: query
  });
};

const useIndexItems = ({
  environmentGuid,
  schemaAlias,
  query,
  disabled
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  query: string;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: [INDEX, environmentGuid, schemaAlias, query],
    queryFn: () =>
      getIndexItems({
        environmentGuid: environmentGuid,
        schemaAlias: schemaAlias,
        query: query
      }),
    enabled: !!environmentGuid && !!schemaAlias && !!query && !disabled
  });
};

export default useIndexItems;
