import * as Sentry from "@sentry/react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation";
import { ActivityLogsProvider } from "./features/logs/contexts/activityLogs";
import { LogsProvider } from "./features/logs/contexts/logs";
import { TreeProvider } from "./features/schemas/contexts/schema-list";
import { NoTenants } from "./features/tenants/components/NoTenants";
import Home from "./Home";
import Layout from "./Layout";
import NotFound from "./NotFound";
import InvitesPage from "./pages/account/InvitesPage";
import AdminDashboard from "./pages/admin/AdminDashboard";
import DeleteTenantPage from "./pages/admin/delete-tenant";
import PreferencesPage from "./pages/admin/preferences";
import TenantDetailsPage from "./pages/admin/TenantDetailsPage";
import TenantsPage from "./pages/admin/tenants";
import UserSearchPage from "./pages/admin/user-search";
import IndexesPage from "./pages/IndexesPage";
import IndexPage from "./pages/IndexPage";
import RouteInspectorPage from "./pages/route-inspector";
import SchemaDetailPage from "./pages/schemas/SchemaDetailsPage";
import SchemasPage from "./pages/SchemasPage";
import ActivityLogsPage from "./pages/settings/activityLog";
import DataSourcesPage from "./pages/settings/dataSources";
import DestinationDetail from "./pages/settings/DestinationDetail";
import Destinations from "./pages/settings/Destinations";
import { DestinationsSettings } from "./pages/settings/DestinationsSettings";
import EnvironmentSettingsPage from "./pages/settings/environment-settings";
import LogsPage from "./pages/settings/logs";
import ManagementClientsPage from "./pages/settings/management-clients";
import UsersPage from "./pages/settings/users";
import SourceEntitiesDetailsPage from "./pages/source-entities";
import SourceEntitiesPage from "./pages/SourceEntitiesPage";
import VersionsPage from "./pages/versions";
import GeneratedViewsPage from "./pages/views";
import ActiveTenantRoute from "./routes/ActiveTenantRoute";
import DestinationsRoute from "./routes/DestinationsRoute";
import IndexesRoute from "./routes/IndexesRoute";
import NoTenantsRoutes from "./routes/NoTenantRoutes";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnknownRoute from "./routes/UnknownRoute";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    Component: ProtectedRoute,
    path: "/",
    children: [
      {
        element: <Layout></Layout>,
        children: [
          {
            path: "/account",
            children: [{ path: "/account/invitations", Component: InvitesPage }]
          },
          {
            Component: ActiveTenantRoute,
            children: [
              {
                children: [
                  {
                    path: "*",
                    element: <UnknownRoute to="/not-found"></UnknownRoute>
                  },
                  { path: "/not-found", Component: NotFound },
                  { index: true, Component: Home },
                  {
                    path: "/authentication ",
                    element: (
                      <LoadingAnimation height={"100%"}></LoadingAnimation>
                    )
                  },
                  {
                    path: "/source-entities",
                    children: [
                      { index: true, Component: SourceEntitiesPage },
                      {
                        path: "/source-entities/:sourceId/:originId",
                        Component: SourceEntitiesDetailsPage
                      }
                    ]
                  },
                  {
                    path: "/schemas",
                    element: (
                      <TreeProvider>
                        <Outlet></Outlet>
                      </TreeProvider>
                    ),

                    children: [
                      {
                        index: true,
                        Component: SchemasPage
                      },
                      {
                        path: "/schemas/:schemaParam",
                        Component: SchemaDetailPage
                      }
                    ]
                  },
                  {
                    path: "/views",
                    Component: GeneratedViewsPage
                  },
                  { path: "/route-inspector", Component: RouteInspectorPage },
                  { path: "/versions", Component: VersionsPage },
                  {
                    Component: IndexesRoute,
                    children: [
                      {
                        path: "/indexes",
                        Component: IndexesPage
                      },
                      {
                        path: "/indexes/:environmentGuid/:alias/:version",
                        Component: IndexPage
                      }
                    ]
                  },
                  {
                    index: true,
                    path: "/settings/data-sources",
                    Component: DataSourcesPage
                  },
                  {
                    path: "/settings/environment-settings",
                    Component: EnvironmentSettingsPage
                  },
                  {
                    path: "/settings/management-clients",
                    Component: ManagementClientsPage
                  },
                  {
                    path: "/settings/users",
                    Component: UsersPage
                  },
                  {
                    path: "/settings/activity-logs",
                    element: (
                      <ActivityLogsProvider>
                        <Outlet></Outlet>
                      </ActivityLogsProvider>
                    ),
                    children: [
                      {
                        index: true,
                        Component: ActivityLogsPage
                      }
                    ]
                  },
                  {
                    path: "/settings/logs",
                    element: (
                      <LogsProvider>
                        <Outlet></Outlet>
                      </LogsProvider>
                    ),
                    children: [
                      {
                        index: true,
                        Component: LogsPage
                      }
                    ]
                  },
                  {
                    Component: DestinationsRoute,
                    children: [
                      {
                        path: "/settings/destinations-settings",
                        Component: DestinationsSettings
                      },
                      {
                        path: "/settings/destinations-settings/:alias",
                        Component: DestinationDetail
                      },
                      {
                        path: "/settings/destinations",
                        Component: Destinations
                      }
                    ]
                  },
                  {
                    path: "/admin",
                    Component: ProtectedAdminRoute,
                    children: [
                      { index: true, Component: AdminDashboard },
                      { path: "/admin/tenants", Component: TenantsPage },
                      {
                        path: "/admin/tenants/:guid",
                        Component: TenantDetailsPage
                      },
                      {
                        path: "/admin/preferences",
                        Component: PreferencesPage
                      },
                      {
                        path: "/admin/preferences",
                        Component: PreferencesPage
                      },
                      {
                        path: "/admin/user-search",
                        Component: UserSearchPage
                      },
                      {
                        path: "/admin/delete-tenant",
                        Component: DeleteTenantPage
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            Component: NoTenantsRoutes,
            children: [
              {
                children: [
                  {
                    path: "/no-tenants",
                    Component: NoTenants
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]);

export default router;
