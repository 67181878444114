import {
  Button,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import {
  faMagnifyingGlass,
  faMagnifyingGlassPlus
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import ReloadButton from "../components/ReloadButton";
import { useCreateSchema } from "../features/schemas/api/createSchema";
import { useDeleteSchema } from "../features/schemas/api/deleteSchema";
import { useDuplicateSchema } from "../features/schemas/api/duplicateSchema";
import { useEditSchema } from "../features/schemas/api/editSchema";
import { useSchemas } from "../features/schemas/api/getSchemas";
import ActionModal from "../features/schemas/components/ActionModal";
import SchemaList from "../features/schemas/components/SchemaList";
import { useTreeState } from "../features/schemas/contexts/schema-list";
import {
  SchemaActionModelSubmissionRequest,
  SchemaActionModelSubmissionResult,
  SchemaListModalType
} from "../features/schemas/types";
import useMixPanel from "../mixpanel/useMixPanel";

interface ActionModalState {
  type: SchemaListModalType;
  schema?: {
    guid?: string | undefined;
    name?: string | undefined;
  };
}

const SchemasPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Action modal
  const deleteSchema = useDeleteSchema();
  const createSchema = useCreateSchema();
  const duplicateSchema = useDuplicateSchema();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actionModalState, setActionModalState] = useState<
    ActionModalState | undefined
  >(undefined);
  const editSchema = useEditSchema(actionModalState?.schema?.guid ?? ``);

  const mixpanel = useMixPanel();
  const navigate = useNavigate();
  const { state, dispatch } = useTreeState();
  const { refetch, isRefetching, data, isLoading } = useSchemas({
    query: state.advancedSearch
      ? searchParams?.get("query") ?? undefined
      : undefined,
    refetch: true
  });
  useEffect(() => {
    const queryParams = new URLSearchParams(searchParams);

    if (state.expandedPaths.size) {
      queryParams.set("expandedPaths", [...state.expandedPaths].join(","));
    }

    if (state.collapsedPaths.size) {
      queryParams.set("collapsedPaths", [...state.collapsedPaths].join(","));
    }

    if (state.filterTerm.length > 0) {
      queryParams.set("filterTerm", state.filterTerm);
    }

    if (state.filterTerm.length === 0) {
      queryParams.delete("filterTerm");
    }

    if (state.query.length > 0) {
      queryParams.set("query", state.query);
    }

    if (state.query.length === 0) {
      queryParams.delete("query");
    }

    queryParams.set("alwaysOpen", state.alwaysOpen.valueOf().toString());

    queryParams.set(
      "advancedSearch",
      state.advancedSearch.valueOf().toString()
    );

    setSearchParams(queryParams);
  }, [
    state.alwaysOpen,
    state.advancedSearch,
    state.filterTerm,
    state.query,
    state.expandedPaths,
    state.collapsedPaths,
    searchParams,
    setSearchParams
  ]);

  const toggleAlwaysOpen = (expand: boolean) => {
    dispatch({
      type: expand ? "expand_all" : "collapse_all"
    });
  };

  const toggleAdvancedSearch = (advancedSearch: boolean) => {
    dispatch({
      type: advancedSearch ? "advanced_search" : "simple_search"
    });
  };

  // User requested schema actions

  const onCreateSchemaRequest = (folderPath: string | undefined) => {
    setActionModalState({
      type: SchemaListModalType.CREATE,
      schema: {
        name: folderPath,
        guid: undefined
      }
    });
    onOpen();
  };

  const onDuplicateSchemaRequest = (schemaGuid: string, schemaName: string) => {
    setActionModalState({
      type: SchemaListModalType.DUPLICATE,
      schema: {
        name: schemaName,
        guid: schemaGuid
      }
    });
    onOpen();
  };

  const onDeleteSchemaRequest = (schemaGuid: string, schemaName: string) => {
    setActionModalState({
      type: SchemaListModalType.DELETE,
      schema: {
        name: schemaName,
        guid: schemaGuid
      }
    });
    onOpen();
  };

  const onEditSchemaRequest = (schemaGuid: string, schemaName: string) => {
    setActionModalState({
      type: SchemaListModalType.EDIT,
      schema: {
        name: schemaName,
        guid: schemaGuid
      }
    });
    onOpen();
  };

  const onActionModalClose = () => {
    onClose();
    setActionModalState(undefined);
  };

  // Handling of action submissions
  const onActionModalSubmit = async (
    request: SchemaActionModelSubmissionRequest
  ): Promise<SchemaActionModelSubmissionResult> => {
    try {
      switch (request.modalType) {
        case SchemaListModalType.CREATE: {
          const { mappingSchemaGuid, idValue } = await createSchema.mutateAsync(
            {
              payload: request
            }
          );
          mixpanel.track("schemaCreated", {
            schemaFormat: request.format,
            schemaId: idValue
          });
          navigate(`/schemas/${mappingSchemaGuid}`);
          break;
        }
        case SchemaListModalType.DELETE: {
          await deleteSchema.mutateAsync({
            schemaGuid: request.schemaGuid
          });
          mixpanel.track("schemaDeleted", {
            id: `gid://MappingSchema/${request.schemaGuid}`
          });
          break;
        }
        case SchemaListModalType.EDIT: {
          await editSchema.mutateAsync({
            schemaGuid: request.schemaGuid,
            payload: {
              name: request.name
            }
          });
          mixpanel.track("schemaEdited", {
            schemaId: `gid://MappingSchema/${request.schemaGuid}`
          });
          break;
        }
        case SchemaListModalType.DUPLICATE: {
          await duplicateSchema.mutateAsync({
            payload: request
          });
          mixpanel.track("schemaDuplicated", {
            schemaId: `gid://Schema/${request.schemaGuid}`
          });
          break;
        }
      }
      return {
        valid: true
      };
    } catch {
      return {
        valid: false
      };
    }
  };

  return (
    <>
      <MetaTitle title="Schemas"></MetaTitle>
      <ContentBox
        title="Schemas"
        headerRight={
          <Flex>
            {state.hasFolders && (
              <Button
                colorScheme="gray"
                onClick={() => toggleAlwaysOpen(!state.alwaysOpen)}
              >
                {state.alwaysOpen ? "Collapse folders" : "Expand folders"}
              </Button>
            )}
            <Button
              ml="2"
              variant="subtle"
              onClick={() => onCreateSchemaRequest(undefined)}
            >
              Create
            </Button>
          </Flex>
        }
        help="All data transformation in Enterspeed is done from a set of schema definitions. Schemas are the glue that ties your existing data to your new layout."
      >
        <SchemaList
          schemas={data ?? []}
          isLoading={isLoading}
          isRefetching={isRefetching}
          alwaysOpen={"true" === searchParams?.get("alwaysOpen")}
          collapsedPaths={searchParams?.get("collapsedPaths")?.split(",") ?? []}
          expandedPaths={searchParams?.get("expandedPaths")?.split(",") ?? []}
          filterTerm={searchParams?.get("filterTerm") ?? ``}
          query={searchParams?.get("query") ?? ``}
          advancedSearch={"true" === searchParams?.get("advancedSearch")}
          filterButtons={
            <>
              <Tooltip
                placement="bottom-start"
                label={
                  state.advancedSearch
                    ? "Go to Filter schemas"
                    : "Go to Search in schemas"
                }
              >
                <IconButton
                  display="flex"
                  onClick={() => toggleAdvancedSearch(!state.advancedSearch)}
                  role="group"
                  _hover={{
                    backgroundColor: "brand.100"
                  }}
                  _active={{
                    backgroundColor: "brand.200"
                  }}
                  variant="ghost"
                  aria-label="Advanced search"
                  icon={
                    <Icon
                      as={FontAwesomeIcon}
                      icon={
                        state.advancedSearch
                          ? faMagnifyingGlass
                          : faMagnifyingGlassPlus
                      }
                    />
                  }
                />
              </Tooltip>
              <ReloadButton onClick={refetch} loading={isRefetching} />
            </>
          }
          onDeleteSchema={onDeleteSchemaRequest}
          onDuplicateSchema={onDuplicateSchemaRequest}
          onCreateSchema={onCreateSchemaRequest}
          onEditSchema={onEditSchemaRequest}
        />
      </ContentBox>

      {actionModalState && (
        <ActionModal
          isOpen={isOpen}
          onClose={onActionModalClose}
          onSubmit={onActionModalSubmit}
          onSuccess={onActionModalClose}
          type={actionModalState.type}
          id={actionModalState.schema?.guid ?? ``}
          name={actionModalState.schema?.name ?? ``}
        />
      )}
    </>
  );
};

export default SchemasPage;
