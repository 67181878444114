import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Outlet } from "react-router-dom";

const IndexesRoute = () => {
  const { indexSchemas } = useFlags();

  if (!indexSchemas) {
    return <Navigate to={"/"}></Navigate>;
  }
  return <Outlet></Outlet>;
};

export default IndexesRoute;
